/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

// Summary And Greeting Section

import emoji from "react-easy-emoji";

const illustration = {
  animated: true // set to false to use static SVG
};

const greeting = {
  username: "Mahmoud Ahmed",
  title: "Hi, I'm Mahmoud",
  subTitle: emoji(
    "A passionate Software Engineer having an experience in designing and developing efficient machine learning based data pipelines and applications with Python / Django / AWS and cutting-edge libraries and frameworks."
  ),
  resumeLink:
    "https://drive.google.com/file/d/1-TMzlw7IP5y_9L8B9HEzRMaRNbnal7Cq/view?usp=share_link",
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/devmahmoud10",
  linkedin: "https://www.linkedin.com/in/mahmoudai/",
  gmail: "mahmod.ahmad.ibrahem@gmail.com",
  medium: "https://medium.com/@mahmoudai",
  stackoverflow: "https://dev.to/mahmoudai",
  // Instagram and Twitter are also supported in the links!
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "",
  skills: [
    emoji("I have a strong expertise in designing, building, and maintaining complex data pipelines and data warehouses, utilizing a variety of ETL technologies and cloud-based data platforms."),
    emoji("I thoroughly analyze my client's business domain and requirements to provide innovative solutions that align with their goals."),
    emoji("With my extensive experience in machine learning, I am capable of developing and integrating ML-based systems to enhance the client's business operations."),
    emoji("I excel at delivering proofs of concept and iterative developed experiments that effectively demonstrate the feasibility of a solution to the stakeholders."),
    emoji("I specialize in creating analytics and reporting services that drive the growth of my clients' products, leveraging modern open source tools and agile methodologies."),
    emoji("In addition, I have a proven track record of building scalable web applications and mission-critical systems that are robust, reliable, and secure, using cutting-edge technology stacks.")
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    // {
    //   skillName: "html-5",
    //   fontAwesomeClassname: "fab fa-html5"
    // },
    // {
    //   skillName: "css3",
    //   fontAwesomeClassname: "fab fa-css3-alt"
    // },
    // {
    //   skillName: "sass",
    //   fontAwesomeClassname: "fab fa-sass"
    // },
    // {
    //   skillName: "JavaScript",
    //   fontAwesomeClassname: "fab fa-js"
    // },
    // {
    //   skillName: "reactjs",
    //   fontAwesomeClassname: "fab fa-react"
    // },
    // {
    //   skillName: "swift",
    //   fontAwesomeClassname: "fab fa-swift"
    // },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "pip",
      fontAwesomeClassname: "fab fa-git"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Ain Shams University",
      logo: require("./assets/images/ASU.png"),
      subHeader: "Bachelors of Computer Science",
      duration: "Sep 2012 - May 2016",
      desc: "Studied a lot of courses related to Software Engineering",
      descBullets: [
        "Artificial Intelligence",
        "Patterns Recognition",
        "Software Engineering",
        "System analysis and Design",
        "Data Structures and Algorithms",
        "Databases Management"

      ]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Programming",
      progressPercentage: "95%"
    },
    {
      Stack: "Data-Engineering",
      progressPercentage: "85%"
    },
    {
      Stack: "Data Analysis", //Insert stack or technology you have experience in
      progressPercentage: "85%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Machine Learning", //Insert stack or technology you have experience in
      progressPercentage: "80%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Data Science", //Insert stack or technology you have experience in
      progressPercentage: "80%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Back-End Development", //Insert stack or technology you have experience in
      progressPercentage: "75%" //Insert relative proficiency in percentage
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Machine Learning Engineer",
      company: "Revosuite Inc.",
      companylogo: require("./assets/images/revosuit.png"),
      date: "April 2018 – Present",
      desc: "Marketing automation platform for pharma powered by AI",
      descBullets: [
        "Designed and developed marketing reports based on machine learning, aiming to improve the level of visits that occurred by medical reps, which led to a significant increase Revosuite users in the last 3 years.",
        "Developed REST API endpoints as part of a high-volume web app, that provide critical connectivity for distributed functionality, and increases system cohesion keeping manageable code complexity.",
        "Wrote multiple automation scripts in python to perform more flexibility and Faster responsiveness to customer needs."
      ]
    },
    {
      role: "Machine Learning Engineer Intern",
      company: "Benchmark Tech Labs",
      companylogo: require("./assets/images/benchmarklabs.png"),
      date: "Jan 2018 – March 2018",
      desc: "A Tech Startups incubator that organically incubates both ideas & minds. The Lab journey so far lead to realization of 3 unique startups that target different sectors and solve different problems.",
      descBullets:
        ["Built a hybrid recommendation system to improve features impact based on user preferences.",
          "Delivering working prototypes and proofs-of-concept for experimentation and iterative development."
        ]
    },
    {
      role: "Trainee",
      company: "IBM",
      companylogo: require("./assets/images/IBM.svg"),
      date: "Aug 2015 – Oct 2015",
      desc: "Academic Program",
      descBullets:
        ["Built a service handling integration between IBM BigInsights and Apache Hadoop to an in-house system.",
          "Introducing a high-level overview of the IBM Cognos Analytics suite of products and their underlying architecture relates to an analytics solution."
        ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "false", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Projects",
  subtitle: "CONTRIBUTIONS TO STARTUPS I AM PROUD TO BE BART OF THEM",
  projects: [
    {
      image: require("./assets/images/sipofink.png"),
      projectName: "Sipof.ink",
      projectDesc: "Facebook Messenger ChatBot helps bookworms to find their next book, winner of The 1st place In Facebook's MENA Contest 2018.",

      footerLink: [
        {
          name: "Visit Website",
          url: "https://sipof.ink/"
        }
        //  you can add extra buttons here.
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Certifications"),
  subtitle:
    "Certifications, and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Data Scientist Nanodegree",
      subtitle:
        "Completed certification from Udacity for Data Science in 2020",
      image: require("./assets/images/udacity.png"),
      footerLink: [
        { name: "Certification", url: "https://graduation.udacity.com/confirm/UCPJ9CRR" },
        {
          name: "Final Project",
          url: "https://github.com/DevMahmoud10/Sparkify"
        }
      ]
    },
    {
      title: "AWS Machine Learning Specialty",
      subtitle:
        "Being an AWS Certified Machine Learning Specialist in 2021",
      image: require("./assets/images/AWS-MachineLearning-Specialty-2020.png"),
      footerLink: [
        {
          name: "Certification",
          url: "https://www.credly.com/badges/7018c5e4-b24a-4171-a303-aa6e298698f3?source=linked_in_profile"
        }
      ]
    },
    {
      title: "Data Engineer Nanodegree",
      subtitle: "Completed certification from Udacity for Data Engineering in 2023",
      image: require("./assets/images/udacity.png"),
      footerLink: [
        { name: "Certification", url: "https://graduation.udacity.com/confirm/NZPNKQNZ" },
        {
          name: "Projects",
          url: "https://github.com/DevMahmoud10/Data-Engineering-Nano-degree-Projects"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",

  blogs: [
    {
      url: "https://open.substack.com/pub/mahmoudai/p/python-sorted-containers-comprehensive-guide?r=azdcw&utm_campaign=post&utm_medium=web",
      title: "Don’t Settle for Unsorted: How Python Sorted Containers Can Revolutionize Your Code?",
      description:
        "In this article let’s take a walkthrough of “Python Sorted Containers” a third-party library that offers a set of data structures maintaining their items in sorted order."
    },
    {
      url: "https://medium.com/mlearning-ai/how-i-passed-aws-machine-learning-specialty-exam-983daa06c436",
      title: "How I passed AWS Machine Learning Specialty Exam?",
      description:
        "My story on the hacking way to be a Certified AWS Machine Learning Specialist maybe inspire you to be also."
    },
    {
      url: "https://mahmoudai.medium.com/how-analyzing-airbnb-data-make-me-excited-to-visit-m%C3%A1laga-89d18d55323b",
      title: "How analyzing Airbnb data make me excited to visit Málaga?",
      description:
        "In this article, we took a look at Málaga Airbnb Data to answer some questions, that might help travelers and hosts also in making better decisions."
    },
    {
      url: "https://medium.com/analytics-vidhya/how-did-i-predict-customer-churn-before-it-knocked-on-my-door-958ade92232b",
      title: "How did I predict customer churn before it knocked on my door?",
      description:
        "Sparkify is a virtual music streaming service aims to make listener experience better, with our product whatever your plan is, but the thing can hurt this experience is churning and for that, we need to know if the customer thinking to churn in the near future or not and then we can take the suitable decision to avoid this, this article aims to explore how I came up with predicting user churn before it happens based on user history."
    },
    {
      url: "https://dev.to/devmahmoud10/types-of-machine-learning-systems-from-other-perspectives-159b",
      title: "Types of Machine Learning Systems From Other Perspectives",
      description:
        "These most common categories is a try to look for machine learning systems from multiple perspectives, based on supervision, Learning, and Prediction type."
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "Talks",
  subtitle: emoji(
    "I LOVE TO SHARE MY KNOWLEDGE AND HELPING COMMUNITY AROUND"
  ),

  talks: [
    {
      title: "Series of Machine Learning Meetups",
      subtitle: "Monthly AI meetups organized by Facebook developer circles (Cairo)",
      slides_url: "https://github.com/BeTechLabs/AI-Comunity-Homeworks",
      event_url: "https://github.com/BeTechLabs/Movies-Recommendation-Case-Study-"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    ""
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  // number: "+201099948666",
  email_address: "mahmod.ahmad.ibrahem@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails
};
